






import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import { Vue, Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
  TimelineChartData,
  TimelineChartSeries
} from "@/models/reporting-analytics/timeline-data";
import { reportingAnalyticsService } from "@/services/reporting-analytics.service";
import InvoiceVolumeAnalyticsStore from "@/store/reporting-analytics/invoice-volume.store";
import Catch from "@/shared/decorators/catch-errors";
import { reportErrorCaptured } from "@/helpers/error-captured";
import { utils } from "@/utils/okta-utils";

@Component({
  components: { apexchart: VueApexCharts }
})
export default class InvoiceVolumeTimelineChart extends Vue {
  // store module
  private readonly invoiceVolumeStore: InvoiceVolumeAnalyticsStore = getModule(
    InvoiceVolumeAnalyticsStore,
    this.$store
  );

  // computed properties
  get invoiceVolumeHistoricalData() {
    return this.invoiceVolumeStore.getInvoiceVolumeHistoricalData;
  }
  get dateRange() {
    return this.invoiceVolumeStore.getDateRange;
  }
  get searchButtonClicked() {
    return this.invoiceVolumeStore.getSearchButtonClicked;
  }

  // reactive class properties
  private series: TimelineChartSeries[] = [];
  private chartOptions = {
    chart: {
      fontFamily: "'Lato', sans-serif",
      toolbar: {
        export: {
          csv: {
            filename: `daily-document-volume-${this.dateRange.startDate}-to-${this.dateRange.endDate}`,
            columnDelimiter: ",",
            headerCategory: "Received Date"
          }
        }
      }
    },
    grid: {
      padding: {
        left: 25,
        right: 25
      }
    },
    xaxis: {
      type: "category",
      labels: {
        formatter: (value: string) => {
          if (value) {
            return utils.utcToLocalDateString(moment(value).toDate(), "L");
          }
        }
      },
      title: {
        text: "Received date"
      }
    },
    yaxis: {
      title: {
        text: "Documents received"
      },
      labels: {
        formatter: function(value: number) {
          if (value) {
            return Math.floor(value);
          }
        }
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
      width: 2
    },
    colors: ["#D06C49"],
    title: {
      text: "Daily Document Volume",
      align: "left"
    },
    tooltip: {
      x: {
        format: "MMMM d yyyy"
      }
    },
    noData: {
      text: "No data for selected options",
      align: "center"
    }
  };

  // lifecycle methods
  async created() {
    if (this.invoiceVolumeHistoricalData.length === 0) {
      await this.fetchNewChartData();
    }
    this.updateChart();
    this.$emit("chartLoaded");
  }

  // methods
  @Catch(reportErrorCaptured)
  async fetchNewChartData() {
    this.$emit("loading");

    const chartData: TimelineChartData[] = await reportingAnalyticsService.loadInvoiceVolumeHistoricalAsync(
      this.dateRange.startDate,
      this.dateRange.endDate
    );
    this.invoiceVolumeStore.updateInvoiceVolumeHistoricalData(chartData);
  }

  updateChart() {
    this.series = [
      {
        name: "Documents received",
        data: this.invoiceVolumeHistoricalData
      }
    ];
  }

  // watchers
  @Watch("searchButtonClicked")
  async updateSearch() {
    await this.fetchNewChartData();
    this.updateChart();
    this.$emit("chartLoaded");
  }
}
