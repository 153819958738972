const defaultFormatter = function(value: string) {
  if (value) {
    return value;
  }
};

export class InvoiceVolumeCharts {
  static singleColumnBaseChartOptions(
    prefix: string,
    dateRange: { [key: string]: string },
    headerCategory: string,
    yText: string,
    xText: string,
    titleText: string
  ) {
    return {
      chart: {
        fontFamily: "'Lato', sans-serif",
        toolbar: {
          export: {
            csv: {
              filename: `${prefix}-${dateRange.startDate}-to-${dateRange.endDate}`,
              columnDelimiter: ",",
              headerCategory: headerCategory
            }
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      yaxis: {
        title: {
          text: yText
        },
        labels: {
          formatter: function(value: number) {
            if (value) {
              return Math.floor(value);
            }
          }
        }
      },
      xaxis: {
        type: "category",
        title: {
          text: xText
        },
        labels: {
          formatter: defaultFormatter,
          trim: false
        }
      },
      tooltip: {
        x: {
          formatter: defaultFormatter
        }
      },
      title: {
        text: titleText,
        align: "left"
      },
      colors: ["#07445b"],
      noData: {
        text: "No data for selected options",
        align: "center"
      }
    };
  }

  static singleColumnSupplierIdChartOptions(
    prefix: string,
    dateRange: { [key: string]: string },
    headerCategory: string,
    yText: string,
    xText: string,
    titleText: string
  ) {
    const chartOptions = InvoiceVolumeCharts.singleColumnBaseChartOptions(
      prefix,
      dateRange,
      headerCategory,
      yText,
      xText,
      titleText
    );
    return {
      ...chartOptions,
      xaxis: {
        ...chartOptions.xaxis,
        labels: {
          formatter: function(value: string) {
            if (value.length) {
              const supplierIdIndex = value.lastIndexOf("/");
              return value.substring(supplierIdIndex + 1); // supplierid
            }
          }
        }
      },
      tooltip: {
        ...chartOptions.tooltip,
        x: {
          formatter: function(value: any) {
            if (value.length) {
              const supplierIdIndex = value.lastIndexOf("/");
              return value
                .substring(0, supplierIdIndex - 1)
                .concat(` (${value.substring(supplierIdIndex + 1)})`);
            }
          }
        }
      }
    };
  }
}
