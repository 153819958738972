






import VueApexCharts from "vue-apexcharts";
import { Vue, Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
  CategoryChartData,
  CategoryChartSeries
} from "@/models/reporting-analytics/category-chart-data";
import { InvoiceVolumeCharts } from "@/components/reporting-analytics/common/invoices-single-column-chart-options";
import { reportingAnalyticsService } from "@/services/reporting-analytics.service";
import InvoiceVolumeAnalyticsStore from "@/store/reporting-analytics/invoice-volume.store";
import Catch from "@/shared/decorators/catch-errors";
import { reportErrorCaptured } from "@/helpers/error-captured";

@Component({
  components: { apexchart: VueApexCharts }
})
export default class InvoicesCancelledBySupplier extends Vue {
  // store module
  private readonly invoiceVolumeStore: InvoiceVolumeAnalyticsStore = getModule(
    InvoiceVolumeAnalyticsStore,
    this.$store
  );

  // computed properties
  get invoiceCancelledBySupplierData() {
    return this.invoiceVolumeStore.getInvoiceCancelledBySupplierData;
  }
  get dateRange() {
    return this.invoiceVolumeStore.getDateRange;
  }
  get searchButtonClicked() {
    return this.invoiceVolumeStore.getSearchButtonClicked;
  }

  // reactive class properties
  private series: CategoryChartSeries[] = [];
  private chartOptions = InvoiceVolumeCharts.singleColumnSupplierIdChartOptions(
    "documents-cancelled-by-supplier",
    this.dateRange,
    "Supplier",
    "Documents cancelled",
    "Supplier ID",
    "Cancelled Documents"
  );

  // lifecycle methods
  async created() {
    if (this.invoiceCancelledBySupplierData.length === 0) {
      await this.fetchNewChartData();
    }
    this.updateChart();
    this.$emit("chartLoaded");
  }

  // methods
  @Catch(reportErrorCaptured)
  async fetchNewChartData() {
    this.$emit("loading");

    const chartData: CategoryChartData[] = await reportingAnalyticsService.loadInvoicesCancelledBySupplierAsync(
      this.dateRange.startDate,
      this.dateRange.endDate
    );
    this.invoiceVolumeStore.updateInvoiceCancelledBySupplierData(chartData);
  }

  updateChart() {
    this.series = [
      {
        name: "Documents cancelled",
        data: this.invoiceCancelledBySupplierData.slice(0, 20)
      }
    ];
  }

  // watchers
  @Watch("searchButtonClicked")
  async updateSearch() {
    await this.fetchNewChartData();
    this.updateChart();
    this.$emit("chartLoaded");
  }
}
