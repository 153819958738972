import options from "@/shared/constants/toast-options";

export const reportErrorCaptured = (error: any, context: any) => {
  context.$emit("errorCaptured", error);
  context.$toasted.show(`<p>Error occurred: ${error.message}</p>`, {
    ...options.ERROR_OPTIONS
  });
};

export const reportErrorFullwidth = (error: any, context: any) => {
  context.$toasted.show(`<p>Error occurred: ${error.message}</p>`, {
    ...options.ERROR_OPTIONS
  });
};
