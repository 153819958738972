












import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class DataSnapshot extends Vue {
  @Prop() dataToDisplay!: number | string;
  @Prop() captionToDisplay!: string;
  @Prop() headerToDisplay?: string | null;
  @Prop() headerId?: string | null;
}
