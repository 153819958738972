





















































































// Custom Components
import DateRangeSelector from "@/components/forms/reporting-analytics/DateRangeSelector.vue";
import DataSnapshot from "@/components/reporting-analytics/data/DataSnapshot.vue";
import InvoicesCancelledBySupplierChart from "@/components/reporting-analytics/charts/InvoicesCancelledBySupplier.vue";
import InvoiceVolumeAnalyticsSkeleton from "@/components/skeletons/InvoiceVolumeAnalyticsSkeleton.vue";
import InvoiceVolumeByAssignmentChart from "@/components/reporting-analytics/charts/InvoicesByAssignment.vue";
import InvoiceVolumeBySupplierChart from "@/components/reporting-analytics/charts/InvoicesBySupplier.vue";
import InvoiceVolumeTimelineChart from "@/components/reporting-analytics/charts/InvoicesTimeline.vue";

// Store
import InvoiceVolumeAnalyticsStore from "@/store/reporting-analytics/invoice-volume.store";

// Third Party Libs
import { getModule } from "vuex-module-decorators";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    "date-range-selector": DateRangeSelector,
    "data-snapshot": DataSnapshot,
    "invoice-volume-timeline-chart": InvoiceVolumeTimelineChart,
    "invoice-volume-supplier-chart": InvoiceVolumeBySupplierChart,
    "invoice-volume-assignment-chart": InvoiceVolumeByAssignmentChart,
    "invoice-cancelled-by-supplier-chart": InvoicesCancelledBySupplierChart,
    skeleton: InvoiceVolumeAnalyticsSkeleton
  }
})
export default class InvoiceVolumeAnalytics extends Vue {
  // store module
  private invoiceVolumeAnalyticsStore: InvoiceVolumeAnalyticsStore = getModule(
    InvoiceVolumeAnalyticsStore,
    this.$store
  );

  // reactive class properties
  private timelineChartLoading = false;
  private assignmentChartLoading = false;
  private supplierChartLoading = false;
  private cancelledChartLoading = false;

  // computed properties
  private get dateRange() {
    return this.invoiceVolumeAnalyticsStore.getDateRange;
  }
  private get numOfSuppliers() {
    return this.invoiceVolumeAnalyticsStore.getNumOfSuppliers;
  }
  private get numOfInvoicesCancelled() {
    return this.invoiceVolumeAnalyticsStore.getNumOfInvoicesCancelled;
  }
  private get numOfInvoiceCategories() {
    return this.invoiceVolumeAnalyticsStore.getNumOfInvoiceCategories;
  }
  private get numOfInvoicesReceived() {
    return this.invoiceVolumeAnalyticsStore.getNumOfInvoicesReceived;
  }
  private get numOfInvoiceCategoriesCaption() {
    return this.numOfInvoiceCategories === 1
      ? "Document Group"
      : "Document Groups";
  }
  private get searchButtonClicked() {
    return this.invoiceVolumeAnalyticsStore.getSearchButtonClicked;
  }

  // methods
  handleDateRangeChange(selection: { startDate: string; endDate: string }) {
    this.invoiceVolumeAnalyticsStore.updateDateRange(selection);
    this.invoiceVolumeAnalyticsStore.clickSearchButton();
  }

  timelineChartLoaded() {
    this.timelineChartLoading = false;
  }
  assignmentChartLoaded() {
    this.assignmentChartLoading = false;
  }
  supplierChartLoaded() {
    this.supplierChartLoading = false;
  }
  cancelledChartLoaded() {
    this.cancelledChartLoading = false;
  }
}
